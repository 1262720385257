import { format } from 'date-fns';
import _ from 'lodash';
;
var DayOfWeekEnum;
(function (DayOfWeekEnum) {
  DayOfWeekEnum[DayOfWeekEnum["Sunday"] = 0] = "Sunday";
  DayOfWeekEnum[DayOfWeekEnum["Monday"] = 1] = "Monday";
  DayOfWeekEnum[DayOfWeekEnum["Tuesday"] = 2] = "Tuesday";
  DayOfWeekEnum[DayOfWeekEnum["Wednesday"] = 3] = "Wednesday";
  DayOfWeekEnum[DayOfWeekEnum["Thursday"] = 4] = "Thursday";
  DayOfWeekEnum[DayOfWeekEnum["Friday"] = 5] = "Friday";
  DayOfWeekEnum[DayOfWeekEnum["Saturday"] = 6] = "Saturday";
})(DayOfWeekEnum || (DayOfWeekEnum = {}));
var ScheduleFrequency;
(function (ScheduleFrequency) {
  ScheduleFrequency[ScheduleFrequency["Minutely"] = 1] = "Minutely";
  ScheduleFrequency[ScheduleFrequency["Hourly"] = 2] = "Hourly";
  ScheduleFrequency[ScheduleFrequency["Daily"] = 3] = "Daily";
  ScheduleFrequency[ScheduleFrequency["Weekly"] = 4] = "Weekly";
  ScheduleFrequency[ScheduleFrequency["Monthly"] = 5] = "Monthly";
  ScheduleFrequency[ScheduleFrequency["Yearly"] = 6] = "Yearly";
})(ScheduleFrequency || (ScheduleFrequency = {}));
;
var ScheduleEnd;
(function (ScheduleEnd) {
  ScheduleEnd[ScheduleEnd["Never"] = 1] = "Never";
  ScheduleEnd[ScheduleEnd["OnThisDay"] = 2] = "OnThisDay";
  ScheduleEnd[ScheduleEnd["AfterNumberOfRuns"] = 3] = "AfterNumberOfRuns";
})(ScheduleEnd || (ScheduleEnd = {}));
;
var MonthlyOccurrence;
(function (MonthlyOccurrence) {
  MonthlyOccurrence[MonthlyOccurrence["OnSpecificDay"] = 1] = "OnSpecificDay";
  MonthlyOccurrence[MonthlyOccurrence["OnEvaluatedDay"] = 2] = "OnEvaluatedDay";
})(MonthlyOccurrence || (MonthlyOccurrence = {}));
;
var DayOfWeekOccurrence;
(function (DayOfWeekOccurrence) {
  DayOfWeekOccurrence[DayOfWeekOccurrence["First"] = 1] = "First";
  DayOfWeekOccurrence[DayOfWeekOccurrence["Second"] = 2] = "Second";
  DayOfWeekOccurrence[DayOfWeekOccurrence["Third"] = 3] = "Third";
  DayOfWeekOccurrence[DayOfWeekOccurrence["Fourth"] = 4] = "Fourth";
  DayOfWeekOccurrence[DayOfWeekOccurrence["Last"] = 5] = "Last";
})(DayOfWeekOccurrence || (DayOfWeekOccurrence = {}));
;
/** An enum to map the months, noting that a JS Date variable uses zero-based index for the month value */
var Month;
(function (Month) {
  Month[Month["January"] = 0] = "January";
  Month[Month["February"] = 1] = "February";
  Month[Month["March"] = 2] = "March";
  Month[Month["April"] = 3] = "April";
  Month[Month["May"] = 4] = "May";
  Month[Month["June"] = 5] = "June";
  Month[Month["July"] = 6] = "July";
  Month[Month["August"] = 7] = "August";
  Month[Month["September"] = 8] = "September";
  Month[Month["October"] = 9] = "October";
  Month[Month["November"] = 10] = "November";
  Month[Month["December"] = 11] = "December";
})(Month || (Month = {}));
;
/**
 * The result of the dialog selections
 *
 */
class Schedule {
  constructor({
    cronExpression,
    scheduleText,
    timeZoneId,
    selectedFrequency,
    selectedOccurrence,
    selectedScheduleEnd,
    scheduleStartDate,
    scheduleEndDate,
    scheduleExecutionTime,
    scheduledNumberOfRuns,
    selectedDayOfMonth,
    selectedDayOfWeek,
    selectedDayOfWeekOccurrence,
    selectedDaysOfWeek,
    selectedMonthlyOccurrence,
    selectedMonth
  } = DEFAULT_SCHEDULE) {
    this.cronExpression = cronExpression;
    this.scheduleText = scheduleText;
    this.timeZoneId = timeZoneId;
    this.selectedFrequency = selectedFrequency;
    this.selectedOccurrence = selectedOccurrence;
    this.selectedScheduleEnd = selectedScheduleEnd;
    this.scheduleStartDate = scheduleStartDate;
    this.scheduleEndDate = scheduleEndDate;
    this.scheduleExecutionTime = scheduleExecutionTime;
    this.scheduledNumberOfRuns = scheduledNumberOfRuns;
    this.selectedDayOfMonth = selectedDayOfMonth;
    this.selectedDayOfWeek = selectedDayOfWeek;
    this.selectedDayOfWeekOccurrence = selectedDayOfWeekOccurrence;
    this.selectedDaysOfWeek = selectedDaysOfWeek;
    this.selectedMonthlyOccurrence = selectedMonthlyOccurrence;
    this.selectedMonth = selectedMonth;
  }
  static getScheduleDescription(schedule) {
    let evaluatedScheduleText = 'Occurs every [@freq] at [@time], starting [@startDate][@endDate]';
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    const timeString = schedule.scheduleExecutionTime.toLocaleTimeString('default', options);
    evaluatedScheduleText = evaluatedScheduleText.replace('[@time]', timeString);
    evaluatedScheduleText = evaluatedScheduleText.replace('[@startDate]', format(schedule.scheduleStartDate, 'EEEE dd MMMM yyyy'));
    let endDateString = '';
    if (schedule.selectedScheduleEnd != ScheduleEnd.Never) {
      if (schedule.selectedScheduleEnd == ScheduleEnd.AfterNumberOfRuns) {
        endDateString = `, ending after ${schedule.scheduledNumberOfRuns} runs`;
      } else {
        endDateString = `, ending ${format(schedule.scheduleEndDate, 'EEEE dd MMMM yyyy')}`;
      }
    }
    evaluatedScheduleText = evaluatedScheduleText.replace('[@endDate]', endDateString);
    let freqString = '';
    switch (schedule.selectedFrequency) {
      case ScheduleFrequency.Minutely:
        {
          break;
        }
      case ScheduleFrequency.Hourly:
        {
          break;
        }
      case ScheduleFrequency.Daily:
        {
          if (schedule.selectedOccurrence == 1) {
            freqString = 'Day';
          } else {
            freqString = `${schedule.selectedOccurrence.toString()} days`;
          }
          break;
        }
      case ScheduleFrequency.Weekly:
        {
          if (schedule.selectedOccurrence == 1) {
            freqString = 'Week';
          } else {
            freqString = `${schedule.selectedOccurrence.toString()} weeks`;
          }
          let daysOfWeekString = '';
          let selectedDaysOfWeek = [];
          // Find the selected options and map them back to the original options array
          _.each(Object.keys(schedule.selectedDaysOfWeek), k => {
            if (schedule.selectedDaysOfWeek[k]) {
              selectedDaysOfWeek.push(_.find(DAYS_OF_THE_WEEK, o => {
                return o.value == k;
              }));
            }
          });
          daysOfWeekString = _.map(selectedDaysOfWeek, item => {
            return item.text;
          }).join(', ');
          freqString += ' on ' + daysOfWeekString;
          break;
        }
      case ScheduleFrequency.Monthly:
        {
          if (schedule.selectedOccurrence == 1) {
            freqString = 'Month';
          } else {
            freqString = `${schedule.selectedOccurrence.toString()} months`;
          }
          if (schedule.selectedMonthlyOccurrence == MonthlyOccurrence.OnSpecificDay) {
            let dayOfMonthString = '';
            if (schedule.selectedDayOfMonth == DAY_OF_MONTH_OPTION_LAST) {
              dayOfMonthString = ' on the Last day of the month';
            } else {
              dayOfMonthString = ` on day ${schedule.selectedDayOfMonth}`;
            }
            freqString += dayOfMonthString;
          } else if (schedule.selectedMonthlyOccurrence == MonthlyOccurrence.OnEvaluatedDay) {
            let selectedDayOfWeekOccurrence = _.find(DAY_OF_WEEK_OCCURRENCE_OPTIONS, o => {
              return o.value == schedule.selectedDayOfWeekOccurrence;
            });
            let selectedDayOfWeek = _.find(DAYS_OF_THE_WEEK, o => {
              return o.value == schedule.selectedDayOfWeek;
            });
            freqString += ` on the ${selectedDayOfWeekOccurrence.text} ${selectedDayOfWeek.text}`;
          }
          break;
        }
      case ScheduleFrequency.Yearly:
        {
          let selectedMonth = _.find(MONTHLY_OPTIONS, o => {
            return o.value == schedule.selectedMonth;
          });
          freqString = `${selectedMonth.text}`;
          if (schedule.selectedMonthlyOccurrence == MonthlyOccurrence.OnSpecificDay) {
            let dayOfMonthString = '';
            if (schedule.selectedDayOfMonth == DAY_OF_MONTH_OPTION_LAST) {
              dayOfMonthString = ' on the Last day of the month';
            } else {
              dayOfMonthString = ` on day ${schedule.selectedDayOfMonth}`;
            }
            freqString += dayOfMonthString;
          } else if (schedule.selectedMonthlyOccurrence == MonthlyOccurrence.OnEvaluatedDay) {
            let selectedDayOfWeekOccurrence = _.find(DAY_OF_WEEK_OCCURRENCE_OPTIONS, o => {
              return o.value == schedule.selectedDayOfWeekOccurrence;
            });
            let selectedDayOfWeek = _.find(DAYS_OF_THE_WEEK, o => {
              return o.value == schedule.selectedDayOfWeek;
            });
            freqString += ` on the ${selectedDayOfWeekOccurrence.text} ${selectedDayOfWeek.text}`;
          }
          break;
        }
    }
    evaluatedScheduleText = evaluatedScheduleText.replace('[@freq]', freqString);
    return evaluatedScheduleText;
  }
}
;
const DEFAULT_SCHEDULED_RUNS_MINUTELY = 5;
const DEFAULT_SCHEDULED_RUNS_HOURLY = 12;
const DEFAULT_SCHEDULED_RUNS_DAILY = 7;
const DEFAULT_SCHEDULED_RUNS_WEEKLY = 4;
const DEFAULT_SCHEDULED_RUNS_MONTHLY = 6;
const DEFAULT_SCHEDULED_RUNS_YEARLY = 2;
const DAY_OF_MONTH_OPTION_LAST = 999;
const DEFAULT_SELECTED_DAYS_OF_WEEK_NONE = {
  [DayOfWeekEnum.Sunday]: false,
  [DayOfWeekEnum.Monday]: false,
  [DayOfWeekEnum.Tuesday]: false,
  [DayOfWeekEnum.Wednesday]: false,
  [DayOfWeekEnum.Thursday]: false,
  [DayOfWeekEnum.Friday]: false,
  [DayOfWeekEnum.Saturday]: false
};
/** A dummy value that has each of the properties defined so we can use object.Keys mapping to populate properties */
const DEFAULT_SCHEDULE = {
  cronExpression: null,
  scheduleText: null,
  timeZoneId: null,
  selectedFrequency: null,
  selectedOccurrence: null,
  selectedScheduleEnd: null,
  scheduleStartDate: null,
  scheduleEndDate: null,
  scheduleExecutionTime: null,
  scheduledNumberOfRuns: null,
  selectedDayOfMonth: null,
  selectedDayOfWeek: null,
  selectedDayOfWeekOccurrence: null,
  selectedDaysOfWeek: null,
  selectedMonthlyOccurrence: null,
  selectedMonth: null
};
// NOTE: 'value' is the value returned from the Date objects 'getDay()' method
const DAYS_OF_THE_WEEK = [{
  text: DayOfWeekEnum[DayOfWeekEnum.Monday],
  label: DayOfWeekEnum[DayOfWeekEnum.Monday][0],
  value: DayOfWeekEnum.Monday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Tuesday],
  label: DayOfWeekEnum[DayOfWeekEnum.Tuesday][0],
  value: DayOfWeekEnum.Tuesday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Wednesday],
  label: DayOfWeekEnum[DayOfWeekEnum.Wednesday][0],
  value: DayOfWeekEnum.Wednesday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Thursday],
  label: DayOfWeekEnum[DayOfWeekEnum.Thursday][0],
  value: DayOfWeekEnum.Thursday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Friday],
  label: DayOfWeekEnum[DayOfWeekEnum.Friday][0],
  value: DayOfWeekEnum.Friday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Saturday],
  label: DayOfWeekEnum[DayOfWeekEnum.Saturday][0],
  value: DayOfWeekEnum.Saturday
}, {
  text: DayOfWeekEnum[DayOfWeekEnum.Sunday],
  label: DayOfWeekEnum[DayOfWeekEnum.Sunday][0],
  value: DayOfWeekEnum.Sunday
}];
const FREQUENCY_OPTIONS = [{
  text: ScheduleFrequency[ScheduleFrequency.Minutely],
  value: ScheduleFrequency.Minutely
}, {
  text: ScheduleFrequency[ScheduleFrequency.Hourly],
  value: ScheduleFrequency.Hourly
}, {
  text: ScheduleFrequency[ScheduleFrequency.Daily],
  value: ScheduleFrequency.Daily
}, {
  text: ScheduleFrequency[ScheduleFrequency.Weekly],
  value: ScheduleFrequency.Weekly
}, {
  text: ScheduleFrequency[ScheduleFrequency.Monthly],
  value: ScheduleFrequency.Monthly
}, {
  text: ScheduleFrequency[ScheduleFrequency.Yearly],
  value: ScheduleFrequency.Yearly
}];
const SCHEDULE_END_OPTIONS = [{
  text: 'Never',
  value: ScheduleEnd.Never
}, {
  text: 'On This Day',
  value: ScheduleEnd.OnThisDay
}, {
  text: 'After',
  value: ScheduleEnd.AfterNumberOfRuns
}];
const MONTHLY_OCCURRENCE_OPTIONS = [{
  text: '',
  value: MonthlyOccurrence.OnSpecificDay
}, {
  text: '',
  value: MonthlyOccurrence.OnEvaluatedDay
}];
const DAY_OF_WEEK_OCCURRENCE_OPTIONS = [{
  text: DayOfWeekOccurrence[DayOfWeekOccurrence.First],
  value: DayOfWeekOccurrence.First
}, {
  text: DayOfWeekOccurrence[DayOfWeekOccurrence.Second],
  value: DayOfWeekOccurrence.Second
}, {
  text: DayOfWeekOccurrence[DayOfWeekOccurrence.Third],
  value: DayOfWeekOccurrence.Third
}, {
  text: DayOfWeekOccurrence[DayOfWeekOccurrence.Fourth],
  value: DayOfWeekOccurrence.Fourth
}, {
  text: DayOfWeekOccurrence[DayOfWeekOccurrence.Last],
  value: DayOfWeekOccurrence.Last
}];
const MONTHLY_OPTIONS = [{
  text: Month[Month.January],
  value: Month.January,
  label: 'JAN'
}, {
  text: Month[Month.February],
  value: Month.February,
  label: 'FEB'
}, {
  text: Month[Month.March],
  value: Month.March,
  label: 'MAR'
}, {
  text: Month[Month.April],
  value: Month.April,
  label: 'APR'
}, {
  text: Month[Month.May],
  value: Month.May,
  label: 'MAY'
}, {
  text: Month[Month.June],
  value: Month.June,
  label: 'JUN'
}, {
  text: Month[Month.July],
  value: Month.July,
  label: 'JUL'
}, {
  text: Month[Month.August],
  value: Month.August,
  label: 'AUG'
}, {
  text: Month[Month.September],
  value: Month.September,
  label: 'SEP'
}, {
  text: Month[Month.October],
  value: Month.October,
  label: 'OCT'
}, {
  text: Month[Month.November],
  value: Month.November,
  label: 'NOV'
}, {
  text: Month[Month.December],
  value: Month.December,
  label: 'DEC'
}];

/**
 * Dialog Button Type for CustomDialogButton configuration.
 * - Default
 * - Next (Navigates to next step of stepper - only for Stepper Dialog's)
 * - Back (Navigates to previous step of stepper - only for Stepper Dialog's)
 */
var DialogButtonType;
(function (DialogButtonType) {
  DialogButtonType[DialogButtonType["Default"] = 0] = "Default";
  DialogButtonType[DialogButtonType["Next"] = 1] = "Next";
  DialogButtonType[DialogButtonType["Back"] = 2] = "Back";
})(DialogButtonType || (DialogButtonType = {}));
/** Dialog Base Config for dialogs
 * - (Optional) Provide type to specify type of dialog content properties/model
 */
class DialogBaseConfig {
  /**
   * Dialog Base Config constructor.
   * @param headerText The header text for the dialog. Defaults to "Message".
   * @param buttons The dialog buttons configuration. Default contains an "ok" and "cancel" button.
   * @param headerColor The color theme of the dialog header. Defaults to "accent-1".
   * @param contentComponentProperties The dialog content component properties object.
   */
  constructor(headerText = "Message", contentComponentProperties = null, buttons = [{
    id: "cancel",
    colorType: "secondary",
    caption: "cancel"
  }, {
    id: "ok",
    colorType: "primary",
    caption: "OK"
  }], headerColor = "accent-1", subheaderText = null) {
    this.headerText = headerText;
    this.headerColor = headerColor;
    this.buttons = buttons;
    this.contentComponentProperties = contentComponentProperties;
    this.subheaderText = subheaderText;
  }
}
var CommentStatisticType;
(function (CommentStatisticType) {
  CommentStatisticType[CommentStatisticType["NegativeSentiment"] = -1] = "NegativeSentiment";
  CommentStatisticType[CommentStatisticType["NeutralSentiment"] = 0] = "NeutralSentiment";
  CommentStatisticType[CommentStatisticType["PositiveSentiment"] = 1] = "PositiveSentiment";
  CommentStatisticType[CommentStatisticType["MixedSentiment"] = 2] = "MixedSentiment";
  CommentStatisticType[CommentStatisticType["Percentage"] = 3] = "Percentage";
})(CommentStatisticType || (CommentStatisticType = {}));
const DEFAULT_OK_BUTTON = {
  id: "ok",
  colorType: "primary",
  caption: "OK",
  canDisable: true
};
const DEFAULT_CANCEL_BUTTON = {
  id: "cancel",
  colorType: "secondary",
  caption: "Cancel",
  canDisable: false
};
const DEFAULT_BUTTONS = [DEFAULT_CANCEL_BUTTON, DEFAULT_OK_BUTTON];
const DEFAULT_STEPPER_BUTTONS = [{
  id: "close",
  colorType: "secondary",
  caption: "close",
  type: DialogButtonType.Default
}, /** Back and Next button configuration with appropriate type required to navigate stepper using a button */
{
  id: "back",
  colorType: "primary",
  caption: "back",
  type: DialogButtonType.Back
}, {
  id: "next",
  colorType: "primary",
  caption: "next",
  type: DialogButtonType.Next,
  canDisable: true
}];

/**
 * Generated bundle index. Do not edit.
 */

export { CommentStatisticType, DAYS_OF_THE_WEEK, DAY_OF_MONTH_OPTION_LAST, DAY_OF_WEEK_OCCURRENCE_OPTIONS, DEFAULT_BUTTONS, DEFAULT_CANCEL_BUTTON, DEFAULT_OK_BUTTON, DEFAULT_SCHEDULE, DEFAULT_SCHEDULED_RUNS_DAILY, DEFAULT_SCHEDULED_RUNS_HOURLY, DEFAULT_SCHEDULED_RUNS_MINUTELY, DEFAULT_SCHEDULED_RUNS_MONTHLY, DEFAULT_SCHEDULED_RUNS_WEEKLY, DEFAULT_SCHEDULED_RUNS_YEARLY, DEFAULT_SELECTED_DAYS_OF_WEEK_NONE, DEFAULT_STEPPER_BUTTONS, DayOfWeekEnum, DayOfWeekOccurrence, DialogBaseConfig, DialogButtonType, FREQUENCY_OPTIONS, MONTHLY_OCCURRENCE_OPTIONS, MONTHLY_OPTIONS, Month, MonthlyOccurrence, SCHEDULE_END_OPTIONS, Schedule, ScheduleEnd, ScheduleFrequency };
