import * as i0 from '@angular/core';
import { input, EventEmitter, signal, Component, Output } from '@angular/core';
import * as i1 from '@theevolvedgroup/component-library/dropdown';
import { DropdownModule } from '@theevolvedgroup/component-library/dropdown';
import _ from 'lodash';
class VariableCodeframeFilterComponent {
  constructor() {
    this.filterVariables = input();
    this.initialFilters = input({}, {
      alias: "filters",
      transform: value => {
        this.filters.set(value);
        return value;
      }
    });
    this.filtersChange = new EventEmitter();
    this.filters = signal({});
    this.filterVariableFields = {
      text: "valueLabel",
      value: "valueKey"
    };
  }
  ngOnInit() {
    /** Initialise filterVariables All Option */
    if (this.filterVariables) {
      const codeframeFilters = this.filterVariables()?.filter(filter => filter.dataStoreVariableKeyValuePair?.length > 0) ?? [];
      _.forEach(codeframeFilters, filter => {
        const allFilter = _.findIndex(filter.dataStoreVariableKeyValuePair, {
          valueKey: "-1"
        });
        if (allFilter == -1) {
          filter.dataStoreVariableKeyValuePair = [{
            valueKey: "-1",
            valueLabel: "All"
          }, ...filter.dataStoreVariableKeyValuePair];
        }
        this.filters.set({
          ...this.filters(),
          [filter.variableName]: "-1"
        });
      });
    }
  }
  onFilterChange(args, filterVariableName) {
    this.filters.set({
      ...this.filters(),
      [filterVariableName]: args.value
    });
    this.filtersChange.emit(this.filters());
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: VariableCodeframeFilterComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "17.0.0",
      version: "18.2.0",
      type: VariableCodeframeFilterComponent,
      isStandalone: true,
      selector: "teg-variable-codeframe-filter",
      inputs: {
        filterVariables: {
          classPropertyName: "filterVariables",
          publicName: "filterVariables",
          isSignal: true,
          isRequired: false,
          transformFunction: null
        },
        initialFilters: {
          classPropertyName: "initialFilters",
          publicName: "filters",
          isSignal: true,
          isRequired: false,
          transformFunction: null
        }
      },
      outputs: {
        filtersChange: "filtersChange"
      },
      ngImport: i0,
      template: "<div class=\"filter-container\">\n    <div class=\"filters\">\n        @for(filter of filterVariables(); track filter.variableName) {\n            @if (filter.dataStoreVariableKeyValuePair?.length > 0) {\n                <div class=\"filter\">\n                    <teg-dropdown [label]=\"filter.variableLabel\"\n                                  [data]=\"filter.dataStoreVariableKeyValuePair\"\n                                  [value]=\"filters()[filter.variableName]\"\n                                  [fields]=\"filterVariableFields\"\n                                  (selectionChanged)=\"onFilterChange($event, filter.variableName)\">\n                    </teg-dropdown>\n                </div>\n            }\n        }\n    </div>\n</div>",
      styles: [":host{--filter-horizontal-spacing: var(--theme-spacing-md);--filter-padding: var(--theme-spacing-lg) var(--filter-horizontal-spacing) var(--theme-spacing-md) var(--filter-horizontal-spacing);--filter-min-width: 200px;--filter-max-width: 320px}.filter-container{display:flex;align-items:center;justify-content:space-between;padding:var(--filter-padding)}.filter-container .filters{display:flex;flex-flow:row wrap;flex:1;gap:var(--theme-spacing-md)}.filter-container .filters .filter{flex:1;min-width:var(--filter-min-width);max-width:var(--filter-max-width)}\n"],
      dependencies: [{
        kind: "ngmodule",
        type: DropdownModule
      }, {
        kind: "component",
        type: i1.DropdownComponent,
        selector: "teg-dropdown",
        inputs: ["data", "selectedData", "fields", "placeholder", "filterPlaceholder", "popupHeight", "popupWidth", "allowFiltering", "mode", "value", "filterValue", "multiSelectViewMode", "disabled", "showSelectAll", "label", "errorMessage", "selectAllText", "disabledTooltip", "tooltip", "tooltipPosition", "itemTemplateContext", "enableItemHtml", "loading", "showInfo", "infoTooltip", "itemTooltipMode"],
        outputs: ["selectionChanged", "listFiltering", "sourceSelection", "multiSelectValueChange", "multiSelectBeforeOpen", "beforeOpen", "selectAll"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: VariableCodeframeFilterComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'teg-variable-codeframe-filter',
      standalone: true,
      imports: [DropdownModule],
      template: "<div class=\"filter-container\">\n    <div class=\"filters\">\n        @for(filter of filterVariables(); track filter.variableName) {\n            @if (filter.dataStoreVariableKeyValuePair?.length > 0) {\n                <div class=\"filter\">\n                    <teg-dropdown [label]=\"filter.variableLabel\"\n                                  [data]=\"filter.dataStoreVariableKeyValuePair\"\n                                  [value]=\"filters()[filter.variableName]\"\n                                  [fields]=\"filterVariableFields\"\n                                  (selectionChanged)=\"onFilterChange($event, filter.variableName)\">\n                    </teg-dropdown>\n                </div>\n            }\n        }\n    </div>\n</div>",
      styles: [":host{--filter-horizontal-spacing: var(--theme-spacing-md);--filter-padding: var(--theme-spacing-lg) var(--filter-horizontal-spacing) var(--theme-spacing-md) var(--filter-horizontal-spacing);--filter-min-width: 200px;--filter-max-width: 320px}.filter-container{display:flex;align-items:center;justify-content:space-between;padding:var(--filter-padding)}.filter-container .filters{display:flex;flex-flow:row wrap;flex:1;gap:var(--theme-spacing-md)}.filter-container .filters .filter{flex:1;min-width:var(--filter-min-width);max-width:var(--filter-max-width)}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    filtersChange: [{
      type: Output
    }]
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { VariableCodeframeFilterComponent };
