<div *ngIf="loading" style="display:flex;align-items:center;justify-content:center;height:100%;">
    Loading...
</div>
<ng-container *ngIf="!loading">
    <div mat-dialog-draggable-title class="dialog-header" *ngIf="data.mode" innerHtml="{{ data.mode }} Account"></div>
    <form #accountForm="ngForm">
        <mat-dialog-content>
            <div style="display: flex;flex-direction: column;">
                <mat-form-field>
                    <input matInput placeholder="Account Name" [(ngModel)]="account.clientAccountName"
                        name="clientAccountName" required #clientAccountName="ngModel" cdkFocusInitial
                        pattern="(?=^.{1,100}$)(?!^[ \-,.]+)(?!^.*[ \-,]$)^[A-Za-z0-9 \-,.]+$"
                        (input)="onAccountNameChanged($event)" />
                    <mat-error *ngIf="clientAccountName.hasError('required')">
                        Account Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="clientAccountName.hasError('pattern')">
                        Account Name is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Account Vanity Name" [(ngModel)]="account.clientAccountVanityName"
                        name="clientAccountVanityName" required #clientAccountVanityName="ngModel"
                        pattern="(?=^.{1,100}$)(?!^[\-]+)(?!^.*[\-]$)^[A-Za-z0-9\-]+$" />
                    <mat-error *ngIf="clientAccountVanityName.hasError('required')">
                        Account Vanity Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="clientAccountVanityName.hasError('pattern')">
                        Account Vanity Name is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <span *ngIf="
                            !iconUrlEditing &&
                            account.clientAccountIconUrl &&
                            account.clientAccountIconUrl.length > 0
                        " matPrefix mat-card-avatar class="mat-card-avatar"><img [src]="account.clientAccountIconUrl"
                            width="70" /></span>
                    <input matInput placeholder="Icon Image (70px x 70px)" [hidden]="
                            !iconUrlEditing &&
                            account.clientAccountIconUrl &&
                            account.clientAccountIconUrl.length > 0
                        " [(ngModel)]="account.clientAccountIconUrl" name="clientAccountIconUrl"
                        (blur)="iconUrlEditing = false" #clientAccountIconUrl="ngModel" required />
                    <button mat-icon-button matSuffix (click)="iconUrlEditing = true">
                        <mat-icon matSuffix matTooltip="Manually edit filename">edit</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix (click)="account.clientAccountIconUrl = ''">
                        <mat-icon matSuffix matTooltip="Clear filename">clear</mat-icon>
                    </button>
                    <label matSuffix for="iconUrlUpload">
                        <button mat-icon-button matSuffix (click)="iconUrlInput.click()">
                            <mat-icon matSuffix matTooltip="Upload a file">file_upload</mat-icon>
                        </button>
                    </label>
                    <mat-error *ngIf="clientAccountIconUrl.hasError('required')">
                        Icon URL is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <input type="file" id="iconUrlUpload" #iconUrlInput ng2FileSelect [style.display]="'none'"
                    [uploader]="uploader" (change)="iconUrlInput.value = ''" />
                <mat-progress-bar *ngIf="response && !response.status" class="upload-spinner" mode="determinate"
                    [value]="response.progress"></mat-progress-bar>
                <mat-checkbox [(ngModel)]="account.config.hideTitle" [labelPosition]="'before'" name="hideTitle">Hide
                    Tile
                    Label</mat-checkbox>
                <mat-form-field><input matInput placeholder="No of years Account Valid" [(ngModel)]="account.clientAccountLicense.validForYears"
                        name="validForYears" pattern="^[0-9]*$" #clientSubAccountYears="ngModel" />
                    <mat-error *ngIf="clientSubAccountYears.hasError('pattern')">
                        ValidForYears <strong>invalid</strong></mat-error>
                </mat-form-field>
                <mat-form-field> <input matInput placeholder="No of licenses" [(ngModel)]="account.clientAccountLicense.licenseCount"
                        name="licenseCount" pattern="^[0-9]*$" #clientSubAccountLicenseCount="ngModel" />
                    <mat-error *ngIf="clientSubAccountLicenseCount.hasError('pattern')">
                        LicenseCount <strong>invalid</strong></mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Contract Date</mat-label>
                    <input matInput [matDatepicker]="picker" name="contractDate" [(ngModel)]="account.contractDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button type="button" color="secondary" (click)="cancel()">
                Cancel
            </button>
            <!-- Can optionally provide a result for the closing dialog. -->
            <button mat-button type="button" color="primary" (click)="save()"
                [disabled]="accountForm.invalid || saving">
                Save
            </button>
        </mat-dialog-actions>
    </form>
</ng-container>