import { DataService } from './services/data.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { MatDialogModule } from "@angular/material/dialog";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStateService } from './services/app-state.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { takeUntil } from 'rxjs/operators';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileUploadModule } from 'ng2-file-upload';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';

import {
  OidcSecurityService,
  AuthInterceptor
} from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LicenseAdminComponent } from './components/license-admin/license-admin.component';
import { UserWithLicensePipe } from '../app/pipes/user-with-license.pipe';
import { UserAdminComponent } from './components/user-admin/user-admin.component';
import { UserRoleComponent } from './components/user-role/user-role.component';
import { AddRoleDialog } from './components/user-role/user-role.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Cloudinary as CloudinaryCore } from 'cloudinary-core';
import { CloudinaryModule } from '@theevolvedgroup/component-library/cloudinary';
import { CommonModule } from '@angular/common';
import { SubAccountDialogComponent } from '../app/components/dialogs/sub-account-dialog/sub-account-dialog.component';
import { AccountDialogComponent } from '../app/components/dialogs/account-dialog/account-dialog.component';
import { ConfirmationDialog } from '../app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthConfigModule } from "./auth-config.module";

/** Component Library Modules */
import { HeaderModule } from "@theevolvedgroup/component-library/header";
import { ContentSection, ContentSectionModule } from "@theevolvedgroup/component-library/content-section";
import { AccountComponent } from './components/account/account.component';
import { MetricsOverviewComponent } from './components/metrics/overview/metrics-overview.component';
import { AccountResolver } from './account-resolver';
import { SubAccountComponent } from './components/sub-account/sub-account.component';
import { OverviewComponent } from './components/overview/overview.component';
import { TableModule } from '@theevolvedgroup/component-library/table';
import { ButtonBaseModule } from '@theevolvedgroup/component-library/button-base';
import { DropdownModule } from '@theevolvedgroup/component-library/dropdown';
import { HomeResolver } from './home-resolver';
import { ShareDialogComponent } from './components/dialogs/share-dialog/share-dialog.component';
import { InputModule } from '@theevolvedgroup/component-library/input';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
import { MetricsResolver } from './metrics-resolver';
import { MetricsReportComponent } from './components/metrics/report/metrics-report.component';
import { SubAccountSelectorDialogComponent } from './components/dialogs/sub-account-selector-dialog/sub-account-selector-dialog.component';
import { SlideToggleModule } from '@theevolvedgroup/component-library/slide-toggle';
import { IconModule } from '@theevolvedgroup/component-library/icon';

export const cloudinaryLib = {
  Cloudinary: CloudinaryCore
};

const HL_DATE_FORMAT = {
  parse: {
    dateInput: ['LL', 'DD/MM/YYYY' ],
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@NgModule({
  declarations: [
    AuthCallbackComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    AppComponent,
    HomeComponent,
    LicenseAdminComponent,
    UserWithLicensePipe,
    UserAdminComponent,
    UserRoleComponent,
    AddRoleDialog,
    SubAccountDialogComponent,
    AccountDialogComponent,
    AccountComponent,
    SubAccountComponent,
    OverviewComponent,
    MetricsOverviewComponent,
    MetricsReportComponent,
    ConfirmationDialog,
    ShareDialogComponent,
    SubAccountSelectorDialogComponent
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule],
  imports: [
    AuthConfigModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatTreeModule,
    CdkTreeModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    NgxDatatableModule,
    MatDialogModule,
    CloudinaryModule.forRoot(cloudinaryLib, { cloud_name: 'twistoflime', upload_preset: 'pmzxaqtf' }),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FileUploadModule,
    MatCheckboxModule,
    DropDownListModule,
    MatProgressBarModule,
    HeaderModule,
    ContentSectionModule,
    TableModule,
    SlideToggleModule,
    ButtonBaseModule,
    IconButtonModule,
    DropdownModule,
    InputModule,
    IconModule
  ],
  providers: [
    AppStateService,
    DataService,
    AuthGuardService,
    AccountResolver,
    HomeResolver,
    MetricsResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_DATE_FORMATS, useValue: HL_DATE_FORMAT},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: "Window",
      useValue: window
    }
  ]
})
export class AppModule {
  private onDestroy$ = new Subject();
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public appState: AppStateService
  ) {
    this.oidcSecurityService.checkSessionChanged$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((checkSession: boolean) => {
        if (checkSession === true && window.location.hostname !== 'localhost') {
          alert('you have been logged out in another window');
          this.oidcSecurityService.logoff();
          return;
        }
      });
  }
}
