/* Alarm rule operators */
export enum Operator {
    Equal = 0,
    LessThan = 1,
    LessThanOrEqual = 2,
    GreaterThan = 3,
    GreaterThanOrEqual = 4,
    NotEqual = 5
}

export const ACCOUNT_METRICS_CONFIG: any = [
    {
        title: "Account Summary",
        subtitle: "Key account metrics",
        action: "metrics_account_summary",
        bannerColor: "success",
        showAccountsFilter: true,
        alarmConfig: [
            {
                field: "activeUsersMonth",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "activeSubAccountCount",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "shareCount",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "surveyCompletedMonth",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "eveProjectCount",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "surveyProjectCount",
                value: 0,
                operator: Operator.Equal,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "180px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "180px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contractDate",
                headerText: "Contract Date",
                width: "140px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "activeUsersMonth",
                headerText: "Active Users: 30 D",
                width: "170px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "sessionDurationMinutes",
                headerText: "Total Sessions (Minutes)",
                width: "200px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "activeSubAccountCount",
                headerText: "Active Sub Acc.: 30 D",
                width: "190px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "shareCount",
                headerText: "Shares: 30 D",
                width: "130px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyCompletedMonth",
                headerText: "Surveys Completed: 30 D",
                width: "210px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "eveProjectCount",
                headerText: "Eve Projects: Contract YTD",
                width: "190px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyProjectCount",
                headerText: "Survey Projects: Contract YTD",
                width: "190px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "User Activity",
        subtitle: "Activity levels and days since last login",
        action: "metrics_account_user_activity",
        bannerColor: "success",
        showEmailFilter: true,
        alarmConfig: [
            {
                field: "loginCount",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "daysSinceLastLogin",
                value: 30,
                operator: Operator.GreaterThan,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "100%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "loginCount",
                headerText: "No. of Visits - Last 30 Days",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "dailyCount",
                headerText: "Active Users - Last 24 Hours",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "monthlyCount",
                headerText: "Active Users - Last 30 Days",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "daysSinceLastLogin",
                headerText: "Days Since Last Activity",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Total Sessions",
        subtitle: "Total session durations (last 30 days)",
        action: "metrics_account_sessions",
        bannerColor: "success",
        showEmailFilter: true,
        alarmConfig: [
            {
                field: "totalSessionMinutes",
                value: 0,
                operator: Operator.Equal,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "totalSessionMinutes",
                headerText: "Total Sessions (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Average Time Per User",
        subtitle: "Average no. of minutes per active user (last 30 days)",
        action: "metrics_account_avg_time_per_user",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "shortestSessonMinutes",
                headerText: "Shortest Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "LongestSessionMinutes",
                headerText: "Longest Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "averageSessionMinutes",
                headerText: "Average Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "SessionCount",
                headerText: "Number of Sessions",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Sub Account Activity",
        subtitle: "Sub Account activity (all time)",
        action: "metrics_account_active_sub_accounts",
        bannerColor: "success",
        showEmailFilter: true,
        alarmConfig: [
            {
                field: "daysSinceLastLogin",
                value: 30,
                operator: Operator.GreaterThan,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "30%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientSubAccountGuid",
                headerText: "Sub Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientSubAccountName",
                headerText: "Sub Account Name",
                width: "30%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastActiveDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "daysSinceLastLogin",
                headerText: "Days Since Active",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    },
    {
        title: "Content Shares",
        subtitle: "Number of shares across all content - last 30 days",
        action: "metrics_account_content_shares",
        bannerColor: "success",
        showEmailFilter: true,
        alarmConfig: [
            {
                field: "totalShareCount",
                value: 0,
                operator: Operator.Equal,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "emailShareCount",
                headerText: "Email Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "groupShareCount",
                headerText: "Group Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "domainShareCount",
                headerText: "Domain Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "totalShareCount",
                headerText: "Total Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            }

        ]
    },
    {
        title: "Survey Counts",
        subtitle: "Number of surveys completed",
        action: "metrics_account_surveys",
        bannerColor: "success",
        showEmailFilter: false,
        alarmConfig: [
            {
                field: "contractDateUpdated",
                value: 0,
                operator: Operator.Equal,
                severity: 2,
                message: "The Contract Date for this Account has not been updated. Please visit System Admin and update the Contract Date."
            },
            {
                field: "surveyCompletedMonth",
                value: 0,
                operator: Operator.Equal,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "300px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "10%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contractDate",
                headerText: "Contract Date",
                width: "140px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "invitationSentMonth",
                headerText: "Invites - 30 Days",
                width: "140px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyStartedMonth",
                headerText: "Starts - 30 Days",
                width: "140px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyCompletedMonth",
                headerText: "Completes - 30 Days",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "invitationSentATD",
                headerText: "Invites - Contract YTD",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyStartedATD",
                headerText: "Starts - Contract YTD",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyCompletedATD",
                headerText: "Complets - Contract YTD",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contractDateUpdated",
                headerText: "ContractDateUpdated",
                width: "10px",
                type: "boolean",
                allowFiltering: false,
                allowSorting: false,
                hidden: true
            },
        ]
    },
    {
        title: "Conversation Counts",
        subtitle: "Number of conversations completed",
        action: "metrics_account_conversations",
        showEmailFilter: true,
    },
    {
        title: "SMS Sent",
        subtitle: "SMS sent from the Account",
        action: "metrics_account_sms_sent",
        showEmailFilter: true,
    },
    {
        title: "Project Counts",
        subtitle: "Number of Survey and Conversational AI Projects",
        action: "metrics_account_project_counts",
        bannerColor: "success",
        showEmailFilter: false,
        alarmConfig: [
            {
                field: "contractDateUpdated",
                value: 0,
                operator: Operator.Equal,
                severity: 2,
                message: "The Contract Date for this Account has not been updated. Please visit System Admin and update the Contract Date."
            },
            {
                field: "eveProjectCount",
                value: 0,
                operator: Operator.Equal,
            },
            {
                field: "surveyProjectCount",
                value: 0,
                operator: Operator.Equal,
            }
        ],
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "300px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "20%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contractDate",
                headerText: "Contract Date",
                width: "140px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "eveProjectCount",
                headerText: "Eve Projects - Contract YTD",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "surveyProjectCount",
                headerText: "Survey Projects - Contract YTD",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "totalProjectCount",
                headerText: "Total Projects - Contract YTD",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contractDateUpdated",
                headerText: "Contract Date has not been updated",
                width: "10px",
                type: "boolean",
                allowFiltering: false,
                allowSorting: false,
                hidden: true
            }
        ]
    },
    {
        title: "Completion Counts",
        subtitle: "Survey and Conversational AI completion counts",
        action: "metrics_account_completion_counts",
        showEmailFilter: true,
    }
];

export const USER_METRICS_CONFIG: any = [
    {
        title: "Active & Inactive Users",
        subtitle: "Active/Inactive users and days since last login",
        action: "metrics_user_active_users",
        bannerColor: "success",
        showEmailFilter: true,
        showInactiveFilter: true,
        alarmConfig: [
            {
                field: "daysSinceLastLogin",
                value: 30,
                operator: Operator.GreaterThan,
            }
        ],
        columns: [
            {
                field: "firstName",
                headerText: "First Name",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastName",
                headerText: "Last Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "email",
                headerText: "Email",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "registeredDateTime",
                headerText: "Registered Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastLoginDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "daysSinceLastLogin",
                headerText: "Days Since Active",
                width: "180px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "companyName",
                headerText: "Company Name",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "languageCode",
                headerText: "Language",
                width: "120px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "countryCode",
                headerText: "Country",
                width: "120px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    },
    {
        title: "Creator Actions",
        subtitle: "Number of Survey and Conversation created, updated and published",
        action: "metrics_user_creator_actions",
        showEmailFilter: true,
    },
    {
        title: "Total Session Minutes",
        subtitle: "Total minutes of login time",
        action: "metrics_user_session_minutes",
        showEmailFilter: true,
    },
    {
        title: "New Users",
        subtitle: "Number of new users registered (last 30 days)",
        action: "metrics_user_new_users",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "firstName",
                headerText: "First Name",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastName",
                headerText: "Last Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "email",
                headerText: "Email",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "registeredDateTime",
                headerText: "Registered Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastLoginDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "companyName",
                headerText: "Company",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "languageCode",
                headerText: "Language",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "countryCode",
                headerText: "Country",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    }
]

export const APPLICATION_METRICS_CONFIG: any = [
    {
        title: "User Activity Level",
        subtitle: "Content created, updated and published",
        action: "metrics_application_user_activity",
        showEmailFilter: true,
    },
    {
        title: "Days Since Last Action",
        subtitle: "Days since the last action per application",
        action: "metrics_application_days_since_last_action",
        showEmailFilter: true,
    },
    {
        title: "New Activity",
        subtitle: "New content created and days since creation",
        action: "metrics_application_new_activity",
        showEmailFilter: true,
    },
    {
        title: "Active Projects",
        subtitle: "Surveys, Conversations and Dashboards with their activity levels",
        action: "metrics_application_active_projects",
        showEmailFilter: true,
    },
    {
        title: "Project Size",
        subtitle: "Survey completions, conversation completions and dashboard logins",
        action: "metrics_application_project_size",
        showEmailFilter: true,
    },
    {
        title: "Text Explorer API Usage",
        subtitle: "COMING SOON",
        action: "metrics_application_te-api-usage",
        showEmailFilter: true,
    }
]
