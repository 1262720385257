import * as i0 from '@angular/core';
import { Pipe } from '@angular/core';
class IsTypeOfPipe {
  transform(value, type) {
    return typeof value === type;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IsTypeOfPipe,
      deps: [],
      target: i0.ɵɵFactoryTarget.Pipe
    });
  }
  static {
    this.ɵpipe = i0.ɵɵngDeclarePipe({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IsTypeOfPipe,
      isStandalone: true,
      name: "isTypeOf"
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: IsTypeOfPipe,
  decorators: [{
    type: Pipe,
    args: [{
      name: 'isTypeOf',
      standalone: true
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { IsTypeOfPipe };
