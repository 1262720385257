import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';

export enum ContentTypeGuids {
    Account = "4ed52271-6f6a-4dd7-84c4-090d31af8169",
    SecurityGroup = "66844fe5-3c87-4b0c-b5fc-7e89e469b4e7",
    Workspace = "f5f2f519-3f48-4f3b-89b8-0de2ef6ed6d8",
    DataExploration = "df1865e0-20bb-4514-aa8c-64972adb58a3",
    DataStore = "8bcc0c60-0432-4692-a56e-7050da3e8d62",
    DataWrangle = "af018894-831d-4eec-91fa-9365cc677582",
    FocusWordsProject = "303148d5-f34c-43b9-b1d8-6a6a91a7a076",
    FocusWordsModel = "6c1d2e56-d750-47d0-bdfe-b510f769f9f4",
    Section = "1f6d1d07-1004-4a48-b491-5005d255cad0",
    Folder = "4a69c733-a290-478d-ab77-6818c6e339ca",
    SectionItem = "4152006c-7120-454c-835d-acb7f9a8af6b",
    Survey = "bf19091c-5077-4293-b81e-f4fbea2283dc",
    SurveyModule = "820609CE-3C5C-48D9-A84B-536A76095824",
    SurveyQuestionSet = "448767CC-6852-4263-AD27-BC42082D4E54",
    CaseManagerProject = "35c66847-f41d-4283-903a-1f59c373ce74",
    CaseManagerWorkgroup = "40c80891-9df4-4a67-8a74-991577a2dd51",
    Dashboard = "f8d53969-6341-4d0f-8c24-33fddc5b3b83",
    ClientFile = "1F2EC7D0-DD15-4375-8FEB-7DB7FA506202",
    FileBankFolder = "D8B0BD40-4BD2-40FA-82AD-B1AA8E374347",
    FileBankSubFolder = "C722D4F4-6698-4E63-BDB8-F08EC3481932",
    PushReportGroup = "8068C3A9-C734-45CF-8E7F-A21E148A9EC4",
    PushReport = "A7961091-230E-49FC-A991-CF71A1F1A2AE",
    PeopleListeningProgram = "03980D13-CDE9-4AB6-AB13-5DB24C047D15",
    DashboardEngine = "5E0B6F07-3F32-47BD-A1A6-FA1AE29AFDE9",
    KHubProject = "630CE286-4B41-4149-B3AE-9C3B8DD513A5",
    DynamicInsightsProject = "2A99227D-3FF8-4E74-A54E-B90D67CC4B51",
    EveBuilderConversation = "0BB0E637-7300-408F-BA77-769FC5E91AE3",
    UnsubscribeGroup = "E5E4C6A7-EBD6-40E8-BA10-2757E32F7F7F",
    SurveyTemplate = "3947c587-e373-4421-b193-27e57463b7d6",
    TextExplorerProject = "DA8F4394-4503-4BC7-9CE1-4B2EC109A755"
}

export enum ContentTypeIds {
    Account = 1,
    SecurityGroup = 2,
    Survey = 13,
}

export class ClientAccount {
    clientAccountGuid: string;
    clientAccountId: number;
    iconUrl: string;
    name: string;
    vanityName: string;
    contentTypeGuid: string;
    contentTypeDisplayName: string;
    isOwner: boolean;
    canAdminister: boolean;
    canAccess: boolean;
    canShare: boolean;
    config: ClientAccountConfig;
    clientAccountName: string;
    clientAccountVanityName: string;
    clientAccountIconUrl: string;
    subAccounts: ClientSubAccount[];
    clientAccountLicenseGuid: string;
    applicationLicenses: ApplicationLicense[];
    isLocked: boolean;
    hasSubAccountCanAdminister?: boolean;
    shareByEmails: ShareByEmail[];
    isDeleted: boolean;
    isArchived: boolean;
    deletedDateTime: Date;
    deletedByEmailAddress: string;
    contractDate: Date;
}

export interface ApplicationLicense {
    applicationId: number;
    applicationGuid: string;
    applicationName: string;
    applicationUrl: string;
    applicationIcon: string;
    requiresLicense: boolean;
    sortOrder: number;
    accountGuid: string;
    subAccountGuid: string;
    applicationTheme: number;
    applicationThemeName: string;
    applicationDescription: string;
    applicationLicenseType: number;
}

export class ClientSubAccount {
    clientSubAccountGuid: string;
    clientSubAccountId: number;
    clientSubAccountName: string;
    clientSubAccountIconUrl: string;
    iconUrl: string;
    name: string;
    clientSubAccountTypeId: number;
    systemManaged: boolean;
    contentTypeGuid: string;
    contentItemGuid: string;
    clientSubAccountSubscriptionStatus: number;
    configJson: string;
    config: ClientSubAccountConfig;
    clientAccountGuid: string; // Legacy support old app state -- should remove once subapps updated
    fileBankFolders?: [];
    //dataStores?: DataStore[];
    sectionData?: SectionData; // Raw data received from api
    canAdminister: boolean;
    canAccess: boolean;
    canShare: boolean;
    isOwner: boolean;
    isLocked: boolean;
    isDefault?: boolean;
    surveys: any[];
    shareByEmails: any[];
    isDeleted: boolean;
    isArchived: boolean;
    deletedDateTime: Date;
    deletedByEmailAddress: string;
}

export interface ClientSubAccountConfig {
    isPeopleListening: boolean;
    hideTitle?: boolean;
    useNewShaper?: boolean;
    useNewControlPanel?: boolean;
    useLearningLoops?: boolean;
    location: string;
    surveyBaseUrl: string;
    enableCaseManagerPIINotifications: boolean;
    enableLLM?: boolean;
}

export interface ClientAccountConfig {
    hideTitle?: boolean;
}

export class ContentSection implements ContentSectionModel {
    activeTileIndex?: number;
    api?: string;
    contentSectionId: number;
    contentSectionGuid: string;
    contentSectionTypeId: ContentSectionTypeEnum;
    contentTypeDisplayName: string;
    contentTypeGuid: string;
    contentTypeId?: number;
    createdByEmailAddress?: string;
    display?: boolean;
    displayRows?: number;
    displayLimit?: number;
    iconUrl?: string;
    isEditor?: boolean;
    isLoading?: boolean;
    isOwner?: boolean;
    isReader?: boolean;
    items: ContentSectionItem[];
    options?: string;
    sortOrder: number;
    styles?: Styles;
    title: string;
    tooltip?: string;
    topLevel: boolean;
    url?: string;
    userOptions?: string;
    noRefresh?: boolean;
    hideSection?: boolean;
}

export class ContentSectionItem implements ContentSectionItemModel {
    contentSectionId?: number;
    contentSectionGuid: string;
    contentSectionItemId?: number;
    contentSectionItemGuid: string;
    contentSectionItemTypeId?: ContentSectionItemTypeEnum;
    contentTypeDisplayName: string;
    contentTypeGuid: string;
    contentItemName?: string;
    contentTypeId?: number;
    display?: boolean;
    dynamicLoaded?: boolean;
    found?: boolean;
    hideTile?: boolean;
    iconUrl: string;
    inheritStyles: Styles;
    isFavourite: boolean;
    securityGroupId?: any;
    sortOrder?: number;
    title: string;
    options?: string;
    userOptions?: string;

    removeFromFavourites?: boolean;

    // for subItem (within subSection)
    subtitle?: string;
    tileText?: string;
    url?: string;
    canShare?: boolean;
    contentItemGuid?: string;
    cssClass?: string;

    // for custom section items
    isAddTile?: boolean;
    hideTitle?: boolean;
    tileImage?: string;
    relatedContentSectionGuid?: string;

    alwaysDisplayOnEdit?: boolean;
    learnMore?: string;
    learnMoreDescription?: string;

    isEditor?: boolean;
    isOwner?: boolean;
    isReader?: boolean;
}

export interface Styles {
    backgroundColor: string;
    color: string;
    themeColor: string;
}

export interface SectionData {
    contentSections: ContentSection[];
    contentSectionItems: ContentSectionItem[];
    contentSectionsOptionsLoaded?: boolean;
    contentSectionsItemsOptionsLoaded?: boolean;
}

export type HelpState = {
    /** Help nav button url */
    url: string;
    /** Help nav button text */
    text: string;
    /** Whether to show or hide help nav button */
    hidden: boolean;
};

export type ContentSectionModel = {
    contentSectionId: number;
    contentSectionGuid: string;
    contentSectionTypeId: ContentSectionTypeEnum;
    sortOrder?: number;
    title: string;
};

export type ContentSectionItemModel = {
    contentSectionItemId?: number;
    contentSectionItemGuid: string;
    contentSectionId?: number;
    title?: string;
    iconUrl?: string;
    sortOrder?: number;
    contentSectionItemTypeId?: ContentSectionItemTypeEnum;
};

/** ContentSectionTypeId Enum based on ContentSectionType Lookup Table in Portal DB. */
export enum ContentSectionTypeEnum {
    Favourite = 1,
    API,
    Managed,
    User,
    SolutionJourneys,
    Surveys
}

/** ContentSectionItemTypeId Enum based on ContentSectionItemType Lookup Table in Portal DB. */
export enum ContentSectionItemTypeEnum {
    Section = 1,
    CustomTile,
    Dashboard,
    Workspace,
    WelcomeToHumanListening,
    Survey
}

export interface UserProfile {
    email: string;
    firstName: string;
    surname: string;
    mobileNumber: string;
    title: string;
    avatarUrl: string;
    companyName: string;
    employeeCount: number;
    language: string;
    timeZoneId: string;
    countryCode: string;

    // external fields
    countryName: string
    fullName: string;
}

export interface Survey {
    surveyGuid: string;
    surveyName: string;
    surveyTitle: string;
    isSurvey: boolean;
    parent: ClientSubAccount;
    shareByEmails: ShareByEmail[];
    dataLoaded: boolean;
    invitationSent: number;
    surveyStarted: number;
    surveyCompleted: number;
}

export interface ShareByEmail {
    shareByEmailId: number;
    emailAddress: string;
    shareRoleId: number;
    isShareByEmail: boolean;
}

export interface ShareRole {
    shareRoleId: number;
    shareRoleName: string;
    shareRoleGuid: string;
    contentTypeGuid: string;
    shareRoleIcon: string;
    isDefault: boolean;
}

export interface MessageDialogModel {
    type?: "Error" | "Warning" | "Information",
    title?: string,
    message: string,
    hasOkButton?: boolean,
    okButtonText?: string,
    hasCancelButton?: boolean,
    cancelButtonText?: string
}

export interface Dictionary {
    [key: string]: any;
}

export const ContentStatusFields: FieldSettingsModel = { text: "contentStatusName", value: "contentStatusId" };

export const ContentStatuses: any[] = [
    {
        contentStatusId: 1,
        contentStatusName: "Active",
    },
    {
        contentStatusId: 2,
        contentStatusName: "Archived",
    },
    {
        contentStatusId: 3,
        contentStatusName: "Deleted",
    }
];

export enum ContentStatusEnum {
    Active = 1,
    Archived = 2,
    Deleted = 3
}