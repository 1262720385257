import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { input, EventEmitter, signal, Component, Input, Output } from '@angular/core';
import * as i2 from '@theevolvedgroup/component-library/action-bar';
import { ActionBarModule } from '@theevolvedgroup/component-library/action-bar';
import { ActionType } from '@theevolvedgroup/component-library/action-bar/types';
import * as i1 from '@theevolvedgroup/component-library/search-filter';
import { SearchFilterModule } from '@theevolvedgroup/component-library/search-filter';
import { VariableCodeframeFilterComponent } from '@theevolvedgroup/component-library/variable-codeframe-filter';
class VariableCodeframeSearchFilterComponent {
  constructor() {
    this.showSearch = false;
    this.showActions = false;
    this.showFilters = false;
    this.initialFilters = input({}, {
      alias: "filters",
      transform: value => {
        this.filters.set(value);
        return value;
      }
    });
    this.initialHasFilters = input(false, {
      alias: 'hasFilters',
      transform: value => {
        const booleanValue = coerceBooleanProperty(value);
        this.hasFilters.set(booleanValue);
        return booleanValue;
      }
    });
    this.filtersChange = new EventEmitter();
    this.iconClicked = new EventEmitter();
    this.menuOptionClicked = new EventEmitter();
    this.filterTermChange = new EventEmitter();
    this.hasFiltersChange = new EventEmitter();
    this.hasFilters = signal(false);
    this.filters = signal({});
  }
  ngOnInit() {
    if (!this.actions) {
      this.actions = [{
        icon: "filter_alt",
        tooltip: "Toggle Filters",
        action: {
          name: "toggle-filters",
          type: ActionType.Toggle,
          data: {
            selected: this.hasFilters(),
            selectedIcon: {
              icon: "filter_alt_off",
              tooltip: "Disable Filters"
            },
            unselectedIcon: {
              icon: "filter_alt",
              tooltip: "Enable Filters"
            }
          }
        },
        condition: this.filterVariables?.length > 0 && this.showFilters
      }];
    }
    /** Initialise hasFilters */
    if (this.filterVariables && this.hasFilters() == null) {
      const codeframeFilters = this.filterVariables?.filter(filter => filter.dataStoreVariableKeyValuePair?.length > 0) ?? [];
      this.setHasFilters(codeframeFilters.length > 0);
    }
  }
  onFilterChange(filters) {
    this.filters.set(filters);
    this.filtersChange.emit(this.filters());
  }
  onIconClicked(action) {
    if (action.name == "toggle-filters") {
      this.setHasFilters(action.data.selected);
    }
    this.iconClicked.emit(action);
  }
  onMenuOptionClicked(event) {
    this.menuOptionClicked.emit(event);
  }
  onSearch(filterTerm) {
    this.filterTermChange.emit(filterTerm);
  }
  setHasFilters(value) {
    this.hasFilters.set(value);
    this.hasFiltersChange.emit(this.hasFilters());
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: VariableCodeframeSearchFilterComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "17.0.0",
      version: "18.2.0",
      type: VariableCodeframeSearchFilterComponent,
      isStandalone: true,
      selector: "teg-variable-codeframe-search-filter",
      inputs: {
        showSearch: {
          classPropertyName: "showSearch",
          publicName: "showSearch",
          isSignal: false,
          isRequired: false,
          transformFunction: coerceBooleanProperty
        },
        showActions: {
          classPropertyName: "showActions",
          publicName: "showActions",
          isSignal: false,
          isRequired: false,
          transformFunction: coerceBooleanProperty
        },
        showFilters: {
          classPropertyName: "showFilters",
          publicName: "showFilters",
          isSignal: false,
          isRequired: false,
          transformFunction: coerceBooleanProperty
        },
        filterVariables: {
          classPropertyName: "filterVariables",
          publicName: "filterVariables",
          isSignal: false,
          isRequired: false,
          transformFunction: null
        },
        actions: {
          classPropertyName: "actions",
          publicName: "actions",
          isSignal: false,
          isRequired: false,
          transformFunction: null
        },
        filterTerm: {
          classPropertyName: "filterTerm",
          publicName: "filterTerm",
          isSignal: false,
          isRequired: false,
          transformFunction: null
        },
        initialFilters: {
          classPropertyName: "initialFilters",
          publicName: "filters",
          isSignal: true,
          isRequired: false,
          transformFunction: null
        },
        initialHasFilters: {
          classPropertyName: "initialHasFilters",
          publicName: "hasFilters",
          isSignal: true,
          isRequired: false,
          transformFunction: null
        }
      },
      outputs: {
        filtersChange: "filtersChange",
        iconClicked: "iconClicked",
        menuOptionClicked: "menuOptionClicked",
        filterTermChange: "filterTermChange",
        hasFiltersChange: "hasFiltersChange"
      },
      ngImport: i0,
      template: "<div class=\"actions\">\n    @if (showSearch) {\n        <teg-search-filter class=\"search-filter\"\n                           [filterTerm]=\"filterTerm\"\n                           (filterTermChange)=\"onSearch($event)\"\n                           [style.--underline-spacing]=\"0\">\n        </teg-search-filter>\n    }\n    @if (showActions) {\n        <teg-action-bar [actions]=\"actions\"\n                        gap=\"var(--theme-spacing-sm)\"\n                        (iconClicked)=\"onIconClicked($event)\"\n                        (menuOptionClicked)=\"onMenuOptionClicked($event)\"></teg-action-bar>\n    }\n</div>\n@if (showFilters && hasFilters()) {\n    <teg-variable-codeframe-filter [style.--filter-horizontal-spacing]=\"'var(--search-filter-horizontal-spacing)'\"\n                                   [filters]=\"filters()\"\n                                   [filterVariables]=\"filterVariables\"\n                                   (filtersChange)=\"onFilterChange($event)\">\n    </teg-variable-codeframe-filter>\n}",
      styles: [":host{--search-filter-horizontal-spacing: var(--theme-spacing-md)}.actions{display:flex;justify-content:end;flex:1;padding:0 var(--search-filter-horizontal-spacing)}.actions:has(.search-filter){justify-content:space-between}\n"],
      dependencies: [{
        kind: "component",
        type: VariableCodeframeFilterComponent,
        selector: "teg-variable-codeframe-filter",
        inputs: ["filterVariables", "filters"],
        outputs: ["filtersChange"]
      }, {
        kind: "ngmodule",
        type: SearchFilterModule
      }, {
        kind: "component",
        type: i1.SearchFilterComponent,
        selector: "teg-search-filter",
        inputs: ["focusSearch", "resetSearch", "filterTerm", "debounceTime"],
        outputs: ["filterTermChange", "clearSearchTerm"]
      }, {
        kind: "ngmodule",
        type: ActionBarModule
      }, {
        kind: "component",
        type: i2.ActionBarComponent,
        selector: "teg-action-bar",
        inputs: ["actions", "gap"],
        outputs: ["iconClicked", "buttonClicked", "menuOptionClicked"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: VariableCodeframeSearchFilterComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'teg-variable-codeframe-search-filter',
      standalone: true,
      imports: [VariableCodeframeFilterComponent, SearchFilterModule, ActionBarModule],
      template: "<div class=\"actions\">\n    @if (showSearch) {\n        <teg-search-filter class=\"search-filter\"\n                           [filterTerm]=\"filterTerm\"\n                           (filterTermChange)=\"onSearch($event)\"\n                           [style.--underline-spacing]=\"0\">\n        </teg-search-filter>\n    }\n    @if (showActions) {\n        <teg-action-bar [actions]=\"actions\"\n                        gap=\"var(--theme-spacing-sm)\"\n                        (iconClicked)=\"onIconClicked($event)\"\n                        (menuOptionClicked)=\"onMenuOptionClicked($event)\"></teg-action-bar>\n    }\n</div>\n@if (showFilters && hasFilters()) {\n    <teg-variable-codeframe-filter [style.--filter-horizontal-spacing]=\"'var(--search-filter-horizontal-spacing)'\"\n                                   [filters]=\"filters()\"\n                                   [filterVariables]=\"filterVariables\"\n                                   (filtersChange)=\"onFilterChange($event)\">\n    </teg-variable-codeframe-filter>\n}",
      styles: [":host{--search-filter-horizontal-spacing: var(--theme-spacing-md)}.actions{display:flex;justify-content:end;flex:1;padding:0 var(--search-filter-horizontal-spacing)}.actions:has(.search-filter){justify-content:space-between}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    showSearch: [{
      type: Input,
      args: [{
        transform: coerceBooleanProperty
      }]
    }],
    showActions: [{
      type: Input,
      args: [{
        transform: coerceBooleanProperty
      }]
    }],
    showFilters: [{
      type: Input,
      args: [{
        transform: coerceBooleanProperty
      }]
    }],
    filterVariables: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    filterTerm: [{
      type: Input
    }],
    filtersChange: [{
      type: Output
    }],
    iconClicked: [{
      type: Output
    }],
    menuOptionClicked: [{
      type: Output
    }],
    filterTermChange: [{
      type: Output
    }],
    hasFiltersChange: [{
      type: Output
    }]
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { VariableCodeframeSearchFilterComponent };
