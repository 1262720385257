import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import * as _ from 'lodash';
class InfiniteScrollerDirective {
  constructor(el) {
    this.el = el;
    /** Number of items to display/load at a time. */
    this.batchSize = 100;
    /** Allowed scroll bottom offset rounding error. */
    this.roundingError = 2;
    this.displayedItems = [];
    this.nextBatch = 0;
  }
  ngOnChanges() {
    this.nextBatch = 0;
    this.displayedItems = [];
    this.initBatches();
    this.loadNextBatch();
    this.scrollListener = () => {
      const {
        scrollHeight,
        scrollTop,
        clientHeight
      } = this.el.nativeElement;
      /** Value should == 0 when at bottom.
       * - Can result in a value > 0 due to rounding errors
       */
      const scrollBottomOffset = Math.abs(scrollHeight - clientHeight - scrollTop);
      if (scrollBottomOffset < this.roundingError) {
        this.loadNextBatch();
      }
    };
    this.el.nativeElement.addEventListener('scroll', this.scrollListener);
  }
  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('scroll', this.scrollListener);
  }
  initBatches() {
    this.batches = _.chunk(this.allItems, this.batchSize);
    this.multiColumnData = this.allItems[0].constructor === Array;
    if (this.multiColumnData) {
      this.batches = _.map(this.allItems, items => {
        let batchArray = _.chunk(items, this.batchSize);
        if (!items) {
          /** Prevent scroller from breaking when only one column of data exists. */
          batchArray = [[]];
        }
        return batchArray;
      });
    }
  }
  loadNextBatch() {
    let nextBatch = this.batches[this.nextBatch];
    if (this.multiColumnData) {
      nextBatch = this.batches?.map(batch => batch[this.nextBatch]);
      if (_.some(nextBatch, column => column != null)) {
        this.updateDisplayedItems(nextBatch);
      }
    } else if (!_.isEmpty(nextBatch)) {
      this.updateDisplayedItems(nextBatch);
    }
  }
  updateDisplayedItems(nextBatch) {
    if (this.multiColumnData && this.displayedItems.length > 0) {
      this.displayedItems = _.map(this.displayedItems, (items, index) => items?.concat(nextBatch[index]));
    } else {
      this.displayedItems = this.displayedItems.concat(nextBatch);
    }
    this.nextBatch++;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: InfiniteScrollerDirective,
      deps: [{
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: InfiniteScrollerDirective,
      selector: "[teg-infinite-scroller]",
      inputs: {
        allItems: "allItems",
        batchSize: "batchSize",
        roundingError: "roundingError"
      },
      exportAs: ["infinite-scroller"],
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: InfiniteScrollerDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: "[teg-infinite-scroller]",
      exportAs: "infinite-scroller"
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }],
  propDecorators: {
    allItems: [{
      type: Input
    }],
    batchSize: [{
      type: Input
    }],
    roundingError: [{
      type: Input
    }]
  }
});
class InfiniteScrollerModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: InfiniteScrollerModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: InfiniteScrollerModule,
      declarations: [InfiniteScrollerDirective],
      exports: [InfiniteScrollerDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: InfiniteScrollerModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: InfiniteScrollerModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [InfiniteScrollerDirective],
      declarations: [InfiniteScrollerDirective],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { InfiniteScrollerDirective, InfiniteScrollerModule };
